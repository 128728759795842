/**
 *
 * Stack
 *
 */

import React from 'react';

import H1 from '../../components/H1';
import Img from '../../components/Img';
import RunkitEmbed from '../../components/RunkitEmbed';
import GetStarted from '../../components/GetStarted';

import mobileScreen from '../../assets/img/mobileScreen.jpg';
import desktopScreen from '../../assets/img/desktopScreen.png';
import adminScreen from '../../assets/img/adminScreen.png';
import accounting from '../../assets/img/accounting.svg';
import graph from '../../assets/img/graph-bar-and-line.svg';
import clearing from '../../assets/img/clearing.svg';
import ledgerlink from '../../assets/img/ledgerlink.svg';
import reports from '../../assets/img/reports.svg';
import identity from '../../assets/img/identity.svg';
import acl from '../../assets/img/access-control.svg';
import flowchart from '../../assets/img/flowchart.svg';

function Stack() {
  return (
    <div className="page-wrapper" id="stack-page">
      <div className="cover-wrapper">
        <div className="container">
          <div className="cover-text">
            <H1>
              <b>
                Empowering market operators with a next generation software
                stack
              </b>
            </H1>
            <p>
              Our market software stack and ecosystem of components is going to
              put you ahead of the curb, whether you are operating a crypto
              exchange, a market for alternative assets, or a traditional stock
              market.
            </p>
          </div>
        </div>
      </div>
      <div className="informations-wrapper">
        <div className="container core-container">
          <div>
            <H1>Core functionalities</H1>
            <p>
              The core of our system has been built from the ground up, it is the beating heart of the our software stack that provides you with the core functionality necessary to operate a market.
            </p>
          </div>
          <div className="core-item">
            <Img src={graph} />
            <p><b>Matching Engine</b></p>
            <p>Matches limit orders, market orders, auctions, initial offerings, and passes results to accounting service for record keeping.</p>
          </div>
          <div className="core-item core-item-2">
            <Img src={accounting} />
            <p><b>Accounting Service</b></p>
            <p>Keeps track of balances and movements of client funds and assets, as well as deposit and withdrawal requests submitted by clients.</p>
          </div>
          <div className="core-item">
            <Img src={clearing} />
            <p><b>Clearing Engine</b></p>
            <p>Calculates net settlement totals for bulk transactions, and passes outputs to Ledger Link for execution of settlement transactions.</p>
          </div>
          <div className="core-item core-item-2">
            <Img src={ledgerlink} />
            <p><b>Ledger Layer</b></p>
            <p>Connects with blockchains and third party APIs, executing settlement transactions, issuing tokens or assets, and receiving transaction data.</p>
          </div>
          <div className="core-item">
            <Img src={identity} />
            <p><b>Identity System</b></p>
            <p>Automates KYC checks, AML checks, and sanction list checks in compliance with all international regulatory requirements.</p>
          </div>
          <div className="core-item core-item-2">
            <Img src={acl} />
            <p><b>User Control System</b></p>
            <p>Enables you to sort users into groups, to assign roles and permissions, and to approve or freeze account functionality at will.</p>
          </div>
          <div className="core-item">
            <Img src={flowchart} />
            <p><b>Operational System</b></p>
            <p>Enables you to manage assets and order books on your market, and to configure your market settings, integrations, and applications.</p>
          </div>
          <div className="core-item core-item-2">
            <Img src={reports} />
            <p><b>Reports Engine</b></p>
            <p>Generates reports automatically from data in all subsystems, making it easy for you or third parties to audit everything is as it should be.</p>
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-grey">
        <div className="container api-container">
          <div className="left-content">
            <H1>Developer friendly APIs</H1>
            <p>
              The platform uses OAuth based authentication, including OTP based two factor authentication for users and administrators, with strict multilayered access control to enable full control over which users or groups of users have access to what API endpoints.
            </p>
            <br />
            <p>
              The APIs are split in two distinct layers, the admin layer and the user layer. The admin layer provides access to trading data from users, it also can block users from trading and freeze accounts, control user rolls, assign users to groups, extract reports with trading and transaction data, manage KYC, approve new users, create new order books or instruments, etc., everything your admins and agents need access to effectively manage your market. Meanwhile, the user layer provides access for authenticated users to their own personal data, trading data, order placement and transaction APIs, and so on.
            </p>
          </div>
          <div className="right-content">
            <RunkitEmbed />
          </div>
        </div>
      </div>
      <div className="informations-wrapper">
        <div className="container">
          <H1>User friendly applications</H1>
          <p>
            We provide you with applications for all major platforms, whether you want to provide your clients with access via the web, or via native apps on mobile or desktop, we&apos;ve got you covered. Simply pick and choose which you want, customize what you wish to, and launch your apps. And once your platform is up and running, you can manage it easily from our admin app.
          </p>
          <br />
          <br />
          <br />
          <div>
            <div className="content-left screenshot desktop-only">
              <Img src={desktopScreen} />
            </div>
            <div className="content-right">
              <h4>Web App</h4>
              <p>
                Assemble multiple interchangeable front end components in order to create a platform that delivers a customized experience to your users, targeted directly at the products you intend to offer in your marketplace.
              </p>
              <p>
                All Braveno front ends are built from modular react.js components, making it easy to customise the platform to your needs by swapping components. You can easily adapt the design through the templating engine to fit the brand of your business, and the desires of your users.
              </p>
            </div>
            <div className="content-left screenshot mobile-only">
              <Img src={desktopScreen} />
            </div>
            <div className="content-left">
              <h4>Native Apps</h4>
              <p>
                Your clients and users want to be able to access their accounts from any device, whether they are on desktop or mobile, and whether they want to use a browser or install an application, with Braveno you get it all out of the box, with native apps for iOS, Android, Windows, MacOS and Linux.
              </p>
            </div>
            <div className="content-right screenshot mobile-screenshot">
              <Img src={mobileScreen} />
            </div>
            <div className="content-left screenshot  desktop-only">
              <Img src={adminScreen} />
            </div>
            <div className="content-right">
              <h4>Admin App</h4>
              <p>
                It is extremely important to be able to maintain control over your marketplace to stay compliant with regulations, and have a complete overview and control of user behaviours. Braveno admin offers a complete suite of administrative tools for controlling and managing your marketplace, reporting interface with visual analytical charts, token management interface, user management with KYC, permissions, multi-level access control layers, configuration of third party components, user support desk and much more. As with Client facing components it is possible to plug in third party or build your own.
              </p>  
            </div>
            <div className="content-left screenshot mobile-only">
              <Img src={adminScreen} />
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default Stack;
