/**
 *
 * Home
 *
 */

import React from 'react';

import H1 from '../../components/H1';
import Img from '../../components/Img';
import GetStarted from '../../components/GetStarted';
import Link from '../../components/Link';
import DemoButton from '../../components/DemoButton';

import imgInfrastructure from '../../assets/img/infrastructure.svg';
import imgVault from '../../assets/img/vault.svg';
import imgCoin from '../../assets/img/coin.svg';
import imgGraph from '../../assets/img/graph-bar-and-line.svg';
import imgJudge from '../../assets/img/judge.svg';
import imgNetwork from '../../assets/img/network.svg';
import imgPaulo from '../../assets/img/paulo-pinto.png';
import imgAlti from '../../assets/img/alti.svg';
import imgStripe from '../../assets/img/stripe.svg';
import imgEthereum from '../../assets/img/ethereum.svg';
import imgMoneyfold from '../../assets/img/moneyfold.svg';
import imgCake from '../../assets/img/cake.svg';
import imgMaker from '../../assets/img/maker.svg';

function Home() {
  return (
    <div className="page-wrapper" id="home-page">
      <div className="cover-wrapper">
        <div className="container">
          <div className="cover-text">
            <H1>
              <b>Welcome to the free market ecosystem</b>
            </H1>
            <p>
              Operating a market should be as simple as operating a webshop,
              whether you are a crypto exchange, a market for alternative
              assets, or a traditional stock market - get ahead of the curve by
              using Braveno.
            </p>
            <div className="demo-button-box">
              <DemoButton />
            </div>
          </div>
          <div className="cover-media">
            <Img src={imgInfrastructure} />
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-white">
        <div className="container benefits-section">
          <H1>
            <b>Everything you need to operate your market like a pro</b>
          </H1>
          <div className="column column-1">
            <Img src={imgVault} />
            <div className="">
              <h4>
                <b>Process currency transactions</b>
              </h4>
              <p>
                Receive deposits and process withdrawals via bank transfers,
                card payments, or blockchain based token transfers. All
                accounting and settlement is taken care of automatically, and
                you can extract all the reports the regulators, auditors and
                accountants hearts desires.
              </p>
            </div>
          </div>
          <div className="column column-2">
            <Img src={imgCoin} />
            <div className="">
              <h4>
                <b>Issue assets and make offerings</b>
              </h4>
              <p>
                Issue and manage assets internal to your market or on any of the
                supported blockchains or service providers in the ecosystem.
                Then make an initial offering, either at a fixed price or
                through an auction if you want to let the market set the price
                for your newly issued asset.
              </p>
            </div>
          </div>
          <div className="column column-3">
            <Img src={imgGraph} />
            <div className="">
              <h4>
                <b>Operate limit order books</b>
              </h4>
              <p>
                Using Braveno you will be operating high performance limit order
                books with all the order types your clients will expect.
                Everything from ordinary GTC or GTD limit orders, to market
                orders, stop orders, and fill-or-kill orders, whatever you need,
                we’ve got you covered.
              </p>
            </div>
          </div>
          <div className="column column-4">
            <Img src={imgJudge} />
            <div className="">
              <h4>
                <b>Stay compliant with the law</b>
              </h4>
              <p>
                Arguably the hardest thing about running a market is staying
                compliant with laws and regulations, with Braveno you will
                breeze through it all. Our ecosystem has all the tools you need,
                from KYC, AML and Sanctions list checks, to reporting and
                auditing, it’s all at your fingertips.
              </p>
            </div>
          </div>
          <div className="demo-button-box">
            <DemoButton />
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-mediumGrey">
        <div className="container section-standard-1">
          <H1>
            The new standard for <b>accessibility</b>
          </H1>
          <div className="text-section">
            <p>
              Operating a market has never had a lower barrier to entry, our
              stack is built to be multi-tenancy native, so we dont have to run
              a separate platform for each market, this enables us to offer you
              the best price in the market by reducing operational costs
              significantly without reducing quality or performance.
              <br />
              And unlike our competitors, our prices aren&apos;t hidden, we are
              proud of our low prices.
            </p>
            <Link url="/pricing">
              <span className="learnmore-link">
                Learn more about our prices &gt;
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-white">
        <div className="container section-standard-2">
          <div className="column-1 desktop-only">
            <Img src={imgNetwork} />
          </div>
          <div className="column-2 text-section">
            <H1>
              The new standard for <b>interoperability</b>
            </H1>
            <p>
              The stack is integrated with everything from banking APIs, e-money
              APIs, & payment APIs like Stripe & Moneyfold, to blockchain
              networks like Ethereum, Bitcoin, & Hyperledger.
              <br />
              You can even tap into other markets for liquidity using our open
              source market making bot.
            </p>
            <Link url="/components">
              <span className="learnmore-link">
                Learn more about all components in the ecosystem &gt;
              </span>
            </Link>
            <Img className="mobile-only network-image" src={imgNetwork} />
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-mediumGrey">
        <div className="container section-standard-3">
          <div className="column-1 text-section">
            <H1>
              The new standard for <b>customizability</b>
            </H1>
            <p>
              Everything on the platform is an API, this means you can easily
              build exactly the user experience you want, on any platform you
              want, in your personal brand, either using our modular apps, or by
              building your own.
            </p>
            <Link url="/stack">
              <span className="learnmore-link">
                Learn more about the software stack &gt;
              </span>
            </Link>
          </div>
          <div className="column-2 text-section">
            <H1>
              The new standard for <b>controllability</b>
            </H1>
            <p>
              We provide you with the tools to make it simple to control your
              market. Whether it is KYC, AML, CTF, sanctions checks, reporting,
              monitoring, or transaction processing we&apos;ve got you covered.
            </p>
            <Link url="/components?category=6">
              <span className="learnmore-link">
                Learn more about administration components &gt;
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="informations-wrapper background-mediumGrey">
        <div className="container section-testimonial">
          <div className="testimonial-img">
            <Img src={imgPaulo} />
          </div>
          <H1>
            <b>
              &lsquo;Super awesome testimonial about Braveno goes here&rsquo;
            </b>
          </H1>
          <div className="text-section">
            <p>
              - Paulo Pinto, founder of Alti Wine Exchange and COO of DIF
              Broker.
            </p>
          </div>
        </div>
      </div> */}
      <div className="informations-wrapper background-white">
        <div className="container section-ecosystem">
          <H1>
            <b>Expand your value chain</b>
          </H1>
          <div className="text-section">
            <p>
              Tap into a growing ecosystem of innovative services and market
              operators.
            </p>
            <Img className="logo-img" src={imgAlti} />
            <Img className="logo-img" src={imgStripe} />
            <Img className="logo-img" src={imgMaker} />
            <Img className="logo-img" src={imgCake} />
            <Img className="logo-img" src={imgMoneyfold} />
            <Img className="logo-img" src={imgEthereum} />
          </div>
        </div>
      </div>
      {/* <div className="informations-wrapper background-grey">
        <div className="container section-pressrelease">
          <H1><b>Press release title goes here</b></H1>
          <div className="text-section">
            <p>
              Press release description goes here. 
            </p>
          </div>
        </div>
      </div> */}
      <GetStarted />
    </div>
  );
}

export default Home;
